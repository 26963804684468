function MainWeb() {
}

MainWeb.fn = {
    init: function () {
        MainWeb.fn.showConfirmPopupSubmitWeb.call(this);
        MainWeb.fn.changeUserPhoto.call();
        //MainWeb.fn.addRoom.call(this);
        //MainWeb.fn.dateTimePicker.call(this);
        MainWeb.fn.proofOfPurChaseChange.call(this);
    },

    proofOfPurChaseChange: function () {
        $('#proof_of_purchase').on('change', function () {
            var file = $('#proof_of_purchase')[0].files[0].name;
            if (file) {
                document.getElementById("input-label").value = file;
            }
        });
    },

    changeUserPhoto: function () {
        $('#who-is-who-photo button').on('click', function () {
            alert('halo');
            $('#img-upload-photo').trigger('click');
        });

        $("#img-upload-photo").on('change', function () {
            changeImage(this);
        });
    },

    rule: function () {
        $(document).ready(function () {
            MainWeb.fn.init.call(this);
        });
    },

    showConfirmPopupSubmitWeb: function () {

        console.log('test');
        $(document).on('click', '.show-confirm', function () {
            var _this = $(this);
            var title = _this.data('title');
            var yesLabel = _this.data('yes_label');
            var noLabel = _this.data('no_label');

            swal.fire({
                title: title,
                icon: "warning",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                confirmButtonText: yesLabel,
                confirmButtonColor: "red",
                cancelButtonText: noLabel,
                preConfirm: async () => {
                    _this.closest('form').submit();
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    swal.fire("Success!", 'success', "success");
                }
            });

            return false;
        });
    },
};

MainWeb.fn.rule();

function changeImage(e) {
    if (e.files && e.files[0]) {
        var name = e.getAttribute('name');
        var reader = new FileReader();
        reader.onload = function (e) {
            $('.form-change #img-' + name)
                .attr('src', e.target.result);
        };

        reader.readAsDataURL(e.files[0]);
    }
}




