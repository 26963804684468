$(function () {
    // Init date picker
    $('.datepicker').datepicker();

    // Click to calendar icon
    $('.fa-calendar-o').on('click', function () {
        let container = $(this).closest('.input-group');
        container.find('.datepicker').focus();
    });

    let tableCart = $('#table-cart');
    let cartSubTotal = $('.cart-subtotal');
    let cartTaxes = $('.cart-taxes');
    let cartTotal = $('.cart-total');
    let cartCounter = $('.cart-counter');

    let messageModal = $('#messageModal');
    let messageContainer = messageModal.find('.modal-body');
    let messageTitleContainer = messageModal.find('.modal-title');

    /**
     * Currency Formatting
     * @link https://blog.abelotech.com/posts/number-currency-formatting-javascript/
     * @param num
     * @returns {string}
     */
    let currencyFormat = function (num) {
        return num.toFixed(2);
    };

    let updateCart = function(data) {
        tableCart.find(':input.product-quantity').each(function () {
            let row = $(this).closest('tr');
            let cartItemId = parseInt(row.attr('data-cart-item-id'));

            $(data.items).each(function (index, item) {
                if(cartItemId == item.id) {
                    let unitPrice = parseFloat(item.unit_price),
                        price = parseFloat(item.price),
                        quantity = item.quantity;

                    row.find('.product-price').attr('data-incl-vat', unitPrice);
                    row.find('.product-price .product-price-excl-vat').text(currencyFormat(unitPrice));
                    row.find('.product-quantity').val(quantity);
                    row.find('.product-sub-price').text(currencyFormat(price));

                    return false; // breaks
                }
            });
        });

        // Cart sub-total price
        cartSubTotal.text(currencyFormat(parseFloat(data.subtotal)));

        // Cart total price
        cartTotal.text(currencyFormat(parseFloat(data.total_price)));

        // Taxes
        // cartTaxes.text(currencyFormat(parseFloat(data.total_taxes)));
    };

    // On change quantity items
    tableCart.on('change', ':input.product-quantity', function () {
        console.log('change cart')
        let container = $(this).closest('tr');

        let id = container.data('id');
        let prevQuantityElement = $(this);
        let prevQuantity = prevQuantityElement.attr('data-prev-quantity');
        let quantity = $(this).val();
        let unit = container.find('.product-unit').val();
        console.log($(this).data('url'));

        $.ajax({
            url: $(this).data('url'),
            type: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {
                product_id: parseInt(id),
                quantity: parseInt(quantity),
            },
            success: function (response) {
                if (response.success) {
                    // messageContainer.text(response.message);
                    // messageModal.modal('show');

                    if(prevQuantity > quantity) {
                        cartCounter.text(parseInt(cartCounter.text()) - (prevQuantity - quantity));
                    }
                    else if(quantity > prevQuantity) {
                        cartCounter.text(parseInt(cartCounter.text()) + (quantity - prevQuantity));
                    }

                    prevQuantityElement.attr('data-prev-quantity', quantity);
                    updateCart(response.data);
                } else {
                    console.log(response);
                    // messageTitleContainer.text(response.data.title);
                    // messageContainer.text(response.message);
                    // messageModal.modal('show');
                }
            },
            error: function (xhr, status, error) {
                let message = '';

                /**
                 * Error 422 Ajax Post using Laravel
                 * @link https://stackoverflow.com/a/49021074
                 */
                if (xhr.status === 422) {
                    let errors = $.parseJSON(xhr.responseText);

                    $.each(errors, function (key, value) {
                        if ($.isPlainObject(value)) {
                            $.each(value, function (key, value) {
                                message += value + "<br/>";
                            });
                        }
                    });
                } else {
                    message = xhr.responseText;
                }
                messageContainer.text(message);
                messageModal.modal('show');
            }
        });
    });

    // Remove a item
    $('.fa-trash-can').on('click', function () {

        let container = $(this).closest('tr');
        let tbodyContainer = $(container).closest('tbody');
        let quantity = container.find('.product-quantity').val();
        let unit = container.find('.product-unit').val();

        container.remove();

        if(tbodyContainer.find('tr').length == 0) {
            $('.cart-container').hide();
            $('.cart-empty').show();
        }

        let id = container.data('id');

        $.ajax({
            url: $(this).data('url'),
            type: 'DELETE',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {
                product_id: parseInt(id),
            },
            success: function (response) {
                if (response.success) {
                    swal.fire({
                        icon: 'success',
                        title: response.data.title,
                        text: response.message,
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    });

                    updateCart(response.data);

                    $(cartCounter).text(parseInt(cartCounter.text()) - quantity);
                } else {
                    messageTitleContainer.text(response.title);
                    messageContainer.text(response.message);

                }
            },
            error: function (xhr, status, error) {
                let message = '';

                /**
                 * Error 422 Ajax Post using Laravel
                 * @link https://stackoverflow.com/a/49021074
                 */
                if (xhr.status === 422) {
                    let errors = $.parseJSON(xhr.responseText);

                    $.each(errors, function (key, value) {
                        if ($.isPlainObject(value)) {
                            $.each(value, function (key, value) {
                                message += value + "<br/>";
                            });
                        }
                    });
                } else {
                    message = xhr.responseText;
                }

                messageContainer.text(message);
                messageModal.show();
            }
        });
    });

    // Click to add to cart
    $('.btn-add-to-cart').on('click', function () {
        let container = $(this).closest('.row-product');
        let id = container.data('id');
        let quantity = container.find('.product-quantity').val();
        let unit = container.find('.product-unit').val();

        if (!quantity || !unit) {
            alert(Lang.get('order.message_enter_quantity'));
            return;
        }

        $.ajax({
            url: $(this).data('url'),
            type: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {
                product_id: parseInt(id),
                unit_id: parseInt(unit),
                quantity: parseInt(quantity)
            },
            success: function (response) {
                if (response.success) {
                    messageTitleContainer.text(response.message.title);
                    messageContainer.text(response.message.message);
                    messageModal.modal('show');



                    $(cartCounter).text(parseInt(cartCounter.text()) + parseInt(quantity));
                } else {
                    messageTitleContainer.text(response.message.title);
                    messageContainer.text(response.message.message);
                    messageModal.modal('show');
                }
            },
            error: function (xhr, status, error) {
                let message = '';

                /**
                 * Error 422 Ajax Post using Laravel
                 * @link https://stackoverflow.com/a/49021074
                 */
                if (xhr.status === 422) {
                    let errors = $.parseJSON(xhr.responseText);

                    $.each(errors, function (key, value) {
                        if ($.isPlainObject(value)) {
                            $.each(value, function (key, value) {
                                message += value + "<br/>";
                            });
                        }
                    });
                } else {
                    message = xhr.responseText;
                }

                messageContainer.text(message);
                messageModal.modal('show');
            }
        });
    });

});