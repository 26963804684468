$(function () {
    $('#main-body').on('click', '.product-fav', function () {
        let star = $(this);
        let rowProduct = $(this).closest('.row-product');

        $.ajax({
            url: star.data('url'),
            type: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function (response) {
                // console.log('response:', response);
                if (response.success) {
                    let isFavorite = getUrlParameter('is_favorite');

                    // Remove item when un-favorite
                    if (isFavorite && !response.data) {
                        rowProduct.remove();
                    }

                    if (star.length > 0) {
                        star.toggleClass('active');
                    }
                }
            }
        });
    });

    // Submit search form
    let searchProductForm = $('#search-product-form');
    searchProductForm.on('change', '[name="sort_by"], [name="sub_categories[]"], [name="brands[]"]', function () {
        searchProductForm.submit();
    });
});

/**
 * Get URL parameter using jQuery
 *
 * @link https://stackoverflow.com/a/21903119
 * @param sParam
 * @returns {boolean|*}
 */
var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};